<template>
  <div>
    <div class="main-title">资料提取</div>
    <div class="content-list-page page-content">
      <div class="margin-b-sm margin-l-sm">
        <span class="fl-l">
          <el-select
            v-model="searchData.status"
            clearable
            placeholder="状态"
            size="mini"
          >
            <el-option :value="1" label="待审核"></el-option>
            <el-option :value="2" label="审核通过"></el-option>
            <el-option :value="3" label="退回"></el-option>
            <el-option :value="4" label="已发送"></el-option>
            <el-option :value="5" label="发送失败"></el-option>
          </el-select>
        </span>
        <span class="fl-l margin-l-sm">
          <el-input
            v-model="searchData.sales_id"
            clearable
            placeholder="报单ID"
            size="mini"
          ></el-input>
        </span>
        <span class="fl-l margin-l-sm">
          <el-input
            v-model="searchData.email"
            clearable
            placeholder="邮箱地址"
            size="mini"
          ></el-input>
        </span>
        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link class="router-link" to="/dataExtract/add">
            <el-button size="small" type="primary">新增</el-button>
          </router-link>
        </span>
      </div>

      <div class="table-content margin-t-sm">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          style="width: 100%; background: #fff"
        >
          <el-table-column align="center" label="ID" property="id" width="80" />
          <el-table-column
            align="center"
            label="报单id"
            property="sales_id"
            width="80"
          />
          <el-table-column align="center" label="课程名称" property="course" />

          <el-table-column align="center" label="申请人">
            <template slot-scope="scope">
              <span>{{
                scope.row.applicant !== 1 ? scope.row.applicant_name : "系统"
              }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="备注" property="remark" />
          <el-table-column align="center" label="邮箱" property="email" />
          <el-table-column
            align="center"
            label="附件"
            property="data_files_list"
            width="200px"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, i) in scope.row.data_files_list"
                :key="i"
                :title="item"
                class="data_files_list"
              >
                {{ item }}
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <el-tag :type="getStatusType(scope.row.status)" size="mini"
                >{{ getStatus(scope.row.status) }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column align="center" label="退回原因">
            <template slot-scope="scope">
              <div v-if="scope.row.status === 3">{{ scope.row.reason }}</div>
              <div v-else>无</div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="role === 1"
            align="center"
            label="错误信息"
            property="error_msg"
          />

          <el-table-column align="center" label="审核人">
            <template slot-scope="scope">
              <span>{{
                scope.row.reviewer !== 1 ? scope.row.reviewer_name : "系统"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="审核时间"
            property="reviewed_at"
          />
          <el-table-column
            align="center"
            label="创建时间"
            property="created_at"
          />
          <el-table-column
            align="center"
            label="更新时间"
            property="updated_at"
          />

          <el-table-column label="操作">
            <template slot-scope="scope">
              <span
                v-if="scope.row.status === 1 || scope.row.status === 3"
                class="margin-r-sm"
              >
                <el-link
                  type="primary"
                  v
                  @click="$router.push('/dataExtract/edit/' + scope.row.id)"
                  >编辑
                </el-link>
              </span>
              <span class="margin-r-sm">
                <el-link type="primary" @click="getNewspaper(scope.row.id)"
                  >查看报单
                </el-link>
              </span>
              <span class="margin-r-sm">
                <el-link
                  v-if="scope.row.status === 5 && role === 1"
                  type="primary"
                  @click="resend(scope.row.id)"
                  >重新发送
                </el-link>
              </span>

              <span
                v-if="scope.row.status === 1 && role === 1"
                class="margin-r-sm"
              >
                <el-link type="primary" @click="verify(scope.row)"
                  >审核
                </el-link>
              </span>

              <span v-if="scope.row.status === 1">
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row.id)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch margin-l-sm">
        <span class="fl-l">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="报单详情"
      width="60%"
    >
      <div v-loading="newspaper">
        <el-descriptions :column="4" border class="margin-top">
          <el-descriptions-item>
            <template slot="label"> 课程名称</template>
            {{ newspaperData.course }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 类型</template>
            {{ getType(newspaperData.type) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 课程状态</template>
            {{ newspaperData.course_status === 0 ? "未开通" : "已开通" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">销售顾问</template>
            {{ newspaperData.sales_consultant }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 收款方式</template>
            {{ getPayType(newspaperData.pay_type) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 收款金额</template>
            {{ newspaperData.price }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 实付金额</template>
            {{ newspaperData.amount }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 支付时间</template>
            {{ newspaperData.pay_at }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 用户昵称</template>
            {{ newspaperData.nickname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 收件人</template>
            {{ newspaperData.user_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 手机号</template>
            {{ newspaperData.phone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 邮箱</template>
            {{ newspaperData.email }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 地址</template>
            {{ newspaperData.address }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 地区</template>
            {{ newspaperData.area }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 年级</template>
            {{ newspaperData.grade }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 状态</template>
            {{ newspaperData.status === 1 ? "已处理" : "未处理" }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 核实状态</template>
            {{ newspaperData.check === 1 ? "已核实" : "未核实" }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 是否需要邮寄</template>
            {{ newspaperData.is_address === 0 ? "否" : "是" }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 是否需要开通课程</template>
            {{ newspaperData.has_course === 0 ? "不需要" : "需要" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 加粉日期</template>
            {{ newspaperData.add_at }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 订单号</template>
            {{ newspaperData.sn }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 支付单号</template>
            {{ newspaperData.pay_sn }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 备注</template>
            {{ newspaperData.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 附件图片</template>
            <div v-if="newspaperData.pic[0]">
              <el-image
                :preview-src-list="newspaperData.pic"
                :src="newspaperData.pic[0]"
                style="width: 100px; height: 100px"
              >
              </el-image>
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建时间</template>
            {{ newspaperData.created_at }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="audit" append-to-body title="审核" width="30%">
      <div>
        <el-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          label-width="100px"
        >
          <el-form-item label="是否通过：">
            <el-radio v-model="form.pass" :label="1" border>通过</el-radio>
            <el-radio v-model="form.pass" :label="0" border>退回</el-radio>
          </el-form-item>

          <el-form-item v-if="form.pass === 0" label="原因：" prop="reason">
            <el-input
              v-model="form.reason"
              style="width: 200px"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="audit = false">取 消</el-button>
        <el-button :loading="auditLoading" type="primary" @click="onSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="particulars"
      append-to-body
      title="附件列表"
      width="30%"
    >
      <div class="table">
        <el-table
          v-loading="particularsLoading"
          :data="particularsData"
          style="width: 100%"
        >
          <el-table-column label="ID" prop="id" width="80" />
          <el-table-column label="名称" prop="name" />
          <el-table-column label="大小">
            <template slot-scope="scope">
              <span>{{ unitConversion(scope.row.size) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="格式" prop="ext" />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { config } from "@/js/config";

export default {
  name: "aimActivity",

  data() {
    return {
      //图片前缀
      path: config.imageUrl,
      dialogVisible: false,
      //表单加载动画
      loading: false,
      //表单数据
      tableData: [],
      //搜索的数据
      searchData: {},
      //分页
      page: 1,
      pageSize: 10,
      total: 0,
      newspaper: false,
      newspaperData: {
        pic: [],
      },
      rules: {
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
      },
      audit: false,
      form: {
        pass: 1,
      },
      particulars: false,
      auditLoading: false,
      particularsData: [],
      particularsLoading: false,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.user.info.role,
    }),
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions("dataExtract", [
      "salesDataFileApplyVerify",
      "salesDataFileApplyList",
      "salesDataFileApplyDel",
      "salesDetail",
      "salesDataFileApplyResend",
      "salesDataFileApplyGet",
    ]),
    unitConversion(size) {
      return (size / 1024 / 1024).toFixed(2) + "M";
    },
    async getParticulars(id) {
      try {
        this.particulars = true;
        this.particularsLoading = true;
        const { data } = await this.salesDataFileApplyGet({ id });
        this.particularsData = data.data_files_detail || [];
      } finally {
        this.particularsLoading = false;
      }
    },
    onSubmit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          try {
            this.auditLoading = true;
            const { res_info } = await this.salesDataFileApplyVerify(this.form);
            if (res_info !== "ok") return;
            this.$message.success("操作成功");
            this.getList();
          } finally {
            this.auditLoading = false;
            this.audit = false;
          }
        }
      });
    },
    async resend(id) {
      try {
        const { res_info } = await this.salesDataFileApplyResend({ id });
        if (res_info !== "ok") return;
        this.$message.success("创建成功");
      } catch (e) {
        console.log(e);
      }
    },
    verify({ id }) {
      this.audit = true;
      this.form.id = id;
    },
    async getNewspaper(id) {
      try {
        this.dialogVisible = true;
        this.newspaper = true;
        const { data } = await this.salesDetail({ id });
        this.newspaperData = data;
        if (data.pic[0]) {
          this.newspaperData.pic = data.pic.map((v) => this.path + v);
        }
      } finally {
        this.newspaper = false;
      }
    },
    async deleted(id) {
      try {
        const { res_info } = await this.salesDataFileApplyDel({ id });
        if (res_info !== "ok") return;
        this.$message.success("删除成功");
        await this.getList();
      } catch (e) {
        console.log(e);
      }
    },
    getPayType(row) {
      switch (row) {
        case 1:
          return "微信";
        case 2:
          return "微信对公";
        case 3:
          return "支付宝";
        case 4:
          return "短书";
      }
    },
    getType(row) {
      switch (row) {
        case 1:
          return "新签";
        case 2:
          return "退费";
        case 3:
          return "转介绍";
        case 4:
          return "扩科目";
        case 5:
          return "续费";
        case 6:
          return "百度";
        case 7:
          return "头条";
      }
    },
    getStatusType(row) {
      switch (row) {
        case 1:
          return "";
        case 2:
          return "success";
        case 3:
          return "warning";
        case 4:
          return "success";
        case 5:
          return "danger";
      }
    },
    getStatus(row) {
      switch (row) {
        case 1:
          return "待审核";
        case 2:
          return "审核通过";
        case 3:
          return "退回";
        case 4:
          return "已发送";
        case 5:
          return "发送失败";
      }
    },
    //搜索
    search() {
      this.page = 1;
      this.getList();
    },
    //分页
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },

    //获取列表数据
    async getList() {
      try {
        this.loading = true;
        let form = {
          ...this.searchData,
          page: this.page,
          pageSize: this.pageSize,
        };
        const { data } = await this.salesDataFileApplyList(form);
        this.tableData = data.list;
        this.total = data.total;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.table {
  height: 50vh;
  overflow: auto;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* 滚动条滑块 */
.table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #eee;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}

.data_files_list {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>